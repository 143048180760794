/*!
 * Pico.css v1.2.1 (https://picocss.com)
 * Copyright 2020 - Licensed under MIT
 */
/**
 * Theme: default
 */
:root {
  --text-font: system-ui, -apple-system, "Segoe UI", "Roboto", "Ubuntu",
    "Cantarell", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  --titles-font: var(--text-font);
  --code-font: "Menlo", "Consolas", "Roboto Mono", "Ubuntu Monospace",
    "Noto Mono", "Oxygen Mono", "Liberation Mono", monospace,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height: 1.5;
  --text-weight: 400;
  --titles-weight: 700;
  --form-element-weight: var(--text-weight);
  --buttons-weight: var(--text-weight);
  --code-weight: var(--text-weight);
  --base-font-xs: 16px;
  --base-font-sm: 17px;
  --base-font-md: 18px;
  --base-font-lg: 19px;
  --base-font-xl: 20px;
  --h1-size: 2rem;
  --h2-size: 1.75rem;
  --h3-size: 1.5rem;
  --h4-size: 1.25rem;
  --h5-size: 1.125rem;
  --h6-size: 1rem;
  --block-round: 0.25rem;
  --form-element-border-width: 1px;
  --form-element-outline-width: 3px;
  --checkbox-radio-border-width: 2px;
  --switch-border-width: 3px;
  --form-element-spacing-vertical: 0.75rem;
  --form-element-spacing-horizontal: 1rem;
  --button-round: var(--block-round);
  --button-border-width: var(--form-element-border-width);
  --button-outline-width: var(--form-element-outline-width);
  --button-spacing-vertical: var(--form-element-spacing-vertical);
  --button-spacing-horizontal: var(--form-element-spacing-horizontal);
  --spacing-gutter: 1rem;
  --spacing-block: 2rem;
  --spacing-factor-xs: 1;
  --spacing-factor-sm: 1.25;
  --spacing-factor-md: 1.5;
  --spacing-factor-lg: 1.75;
  --spacing-factor-xl: 2;
  --spacing-typography: 1.5rem;
  --spacing-form-element: 0.25rem;
  --transition: 0.2s ease-in-out;
}

:root {
  --icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(115, 130, 140, 0.999)' opacity='0.66' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-date: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(115, 130, 140, 0.999)' opacity='0.66' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
  --icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(115, 130, 140, 0.999)' opacity='0.66' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(115, 130, 140, 0.999)' opacity='0.66' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  --icon-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
  --icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(40, 138, 106, 0.999)' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgba(185, 70, 70, 0.999)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
}

[data-theme="light"],
:root:not([data-theme="dark"]) {
  --background: #fff;
  --text: #415462;
  --h1: #1b2832;
  --h2: #2c3d49;
  --h3: #415462;
  --h4: #596b78;
  --h5: #73828c;
  --h6: #8a99a3;
  --primary: #1095c1;
  --primary-border: var(--primary);
  --primary-hover: #08769b;
  --primary-hover-border: var(--primary-hover);
  --primary-focus: rgba(16, 149, 193, 0.125);
  --primary-inverse: #fff;
  --secondary: #73828c;
  --secondary-border: var(--secondary);
  --secondary-hover: #415462;
  --secondary-hover-border: var(--secondary-hover);
  --secondary-focus: rgba(115, 130, 140, 0.125);
  --secondary-inverse: #fff;
  --contrast: #2c3d49;
  --contrast-border: var(--contrast);
  --contrast-hover: #0d1419;
  --contrast-hover-border: var(--contrast-hover);
  --contrast-focus: rgba(115, 130, 140, 0.125);
  --contrast-inverse: #fff;
  --input-background: #fff;
  --input-border: #c8d1d8;
  --input-hover-background: var(--input-background);
  --input-hover-border: var(--primary);
  --input-focus: var(--primary-focus);
  --input-inverse: var(--primary-inverse);
  --button-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --button-hover-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --valid: #288a6a;
  --invalid: #b94646;
  --mark: rgba(255, 223, 128, 0.33);
  --mark-text: #2c3d49;
  --muted-text: #7e8d98;
  --muted-background: #edf0f3;
  --muted-border: #edf0f3;
  --card-background: #fff;
  --card-sections: #f3f5f7;
  --card-shadow: 0 0.125rem 1rem rgba(27, 40, 50, 0.04),
    0 0.125rem 2rem rgba(27, 40, 50, 0.08),
    0 0 0 0.0625rem rgba(27, 40, 50, 0.024);
  --code-background: #f3f5f7;
  --code-inlined: #edf0f3;
  --code-color-1: #73828c;
  --code-color-2: #b34d80;
  --code-color-3: #3d888f;
  --code-color-4: #998866;
  --code-color-5: #96a4ae;
  --table-border: rgba(237, 240, 243, 0.75);
  --table-stripping: rgba(115, 130, 140, 0.075);
}

@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme="light"]) {
    --background: #10181e;
    --text: #a2afb9;
    --h1: #edf0f3;
    --h2: #d5dce2;
    --h3: #bbc6ce;
    --h4: #a2afb9;
    --h5: #8a99a3;
    --h6: #73828c;
    --primary: #1095c1;
    --primary-border: var(--primary);
    --primary-hover: #1ab3e6;
    --primary-hover-border: var(--primary-hover);
    --primary-focus: rgba(16, 149, 193, 0.25);
    --primary-inverse: #fff;
    --secondary: #596b78;
    --secondary-border: var(--secondary);
    --secondary-hover: #73828c;
    --secondary-hover-border: var(--secondary-hover);
    --secondary-focus: rgba(89, 107, 120, 0.25);
    --secondary-inverse: #fff;
    --contrast: #d5dce2;
    --contrast-border: var(--contrast);
    --contrast-hover: #fff;
    --contrast-hover-border: var(--contrast-hover);
    --contrast-focus: rgba(89, 107, 120, 0.25);
    --contrast-inverse: #10181e;
    --input-background: #10181e;
    --input-border: #374956;
    --input-hover-background: var(--input-background);
    --input-hover-border: var(--primary);
    --input-focus: var(--primary-focus);
    --input-inverse: var(--primary-inverse);
    --button-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    --button-hover-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    --valid: #1f7a5c;
    --invalid: #943838;
    --mark: rgba(255, 223, 128, 0.1875);
    --mark-text: #fff;
    --muted-text: #73828c;
    --muted-background: #23333e;
    --muted-border: #23333e;
    --card-background: #17232b;
    --card-sections: #141d24;
    --card-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.08),
      0 0.125rem 2rem rgba(0, 0, 0, 0.04), 0 0 0 0.0625rem rgba(0, 0, 0, 0.1);
    --code-background: #141d24;
    --code-inlined: rgba(65, 84, 98, 0.25);
    --code-color-1: #73828c;
    --code-color-2: #a65980;
    --code-color-3: #599fa6;
    --code-color-4: #8c8473;
    --code-color-5: #4d606d;
    --table-border: rgba(115, 130, 140, 0.075);
    --table-stripping: rgba(115, 130, 140, 0.05);
  }
}

[data-theme="dark"] {
  --background: #10181e;
  --text: #a2afb9;
  --h1: #edf0f3;
  --h2: #d5dce2;
  --h3: #bbc6ce;
  --h4: #a2afb9;
  --h5: #8a99a3;
  --h6: #73828c;
  --primary: #1095c1;
  --primary-border: var(--primary);
  --primary-hover: #1ab3e6;
  --primary-hover-border: var(--primary-hover);
  --primary-focus: rgba(16, 149, 193, 0.25);
  --primary-inverse: #fff;
  --secondary: #596b78;
  --secondary-border: var(--secondary);
  --secondary-hover: #73828c;
  --secondary-hover-border: var(--secondary-hover);
  --secondary-focus: rgba(89, 107, 120, 0.25);
  --secondary-inverse: #fff;
  --contrast: #d5dce2;
  --contrast-border: var(--contrast);
  --contrast-hover: #fff;
  --contrast-hover-border: var(--contrast-hover);
  --contrast-focus: rgba(89, 107, 120, 0.25);
  --contrast-inverse: #10181e;
  --input-background: #10181e;
  --input-border: #374956;
  --input-hover-background: var(--input-background);
  --input-hover-border: var(--primary);
  --input-focus: var(--primary-focus);
  --input-inverse: var(--primary-inverse);
  --button-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --button-hover-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --valid: #1f7a5c;
  --invalid: #943838;
  --mark: rgba(255, 223, 128, 0.1875);
  --mark-text: #fff;
  --muted-text: #73828c;
  --muted-background: #23333e;
  --muted-border: #23333e;
  --card-background: #17232b;
  --card-sections: #141d24;
  --card-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.08),
    0 0.125rem 2rem rgba(0, 0, 0, 0.04), 0 0 0 0.0625rem rgba(0, 0, 0, 0.1);
  --code-background: #141d24;
  --code-inlined: rgba(65, 84, 98, 0.25);
  --code-color-1: #73828c;
  --code-color-2: #a65980;
  --code-color-3: #599fa6;
  --code-color-4: #8c8473;
  --code-color-5: #4d606d;
  --table-border: rgba(115, 130, 140, 0.075);
  --table-stripping: rgba(115, 130, 140, 0.05);
}

/**
 * Document
 * Content-box & Responsive typography
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tab-size: 4;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background: var(--background);
  color: var(--text);
  font-family: var(--text-font);
  font-size: var(--base-font-xs);
  font-weight: var(--text-weight);
  line-height: var(--line-height);
  text-rendering: optimizeLegibility;
  cursor: default;
}

@media (min-width: 576px) {
  html {
    font-size: var(--base-font-sm);
  }
}

@media (min-width: 768px) {
  html {
    font-size: var(--base-font-md);
  }
}

@media (min-width: 992px) {
  html {
    font-size: var(--base-font-lg);
  }
}

@media (min-width: 1200px) {
  html {
    font-size: var(--base-font-xl);
  }
}

/**
 * Sectioning
 * Container and responsive spacings for header, main, footer
 */
main {
  display: block;
}

body {
  width: 100%;
  margin: 0;
}

body > header,
body > main,
body > footer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: var(--spacing-block) 0;
}

@media (min-width: 576px) {
  body > header,
  body > main,
  body > footer {
    padding: calc(var(--spacing-block) * var(--spacing-factor-sm)) 0;
  }
}

@media (min-width: 768px) {
  body > header,
  body > main,
  body > footer {
    padding: calc(var(--spacing-block) * var(--spacing-factor-md)) 0;
  }
}

@media (min-width: 992px) {
  body > header,
  body > main,
  body > footer {
    padding: calc(var(--spacing-block) * var(--spacing-factor-lg)) 0;
  }
}

@media (min-width: 1200px) {
  body > header,
  body > main,
  body > footer {
    padding: calc(var(--spacing-block) * var(--spacing-factor-xl)) 0;
  }
}

/**
 * Container
 */
.container,
.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--spacing-gutter);
  padding-left: var(--spacing-gutter);
}

@media (min-width: 576px) {
  .container {
    max-width: 510px;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 920px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1130px;
  }
}

/**
 * Section
 * Responsive spacings for section
 */
section {
  margin-bottom: calc(var(--spacing-block) * var(--spacing-factor-xs) * 2);
}

@media (min-width: 576px) {
  section {
    margin-bottom: calc(var(--spacing-block) * var(--spacing-factor-sm) * 2);
  }
}

@media (min-width: 768px) {
  section {
    margin-bottom: calc(var(--spacing-block) * var(--spacing-factor-md) * 2);
  }
}

@media (min-width: 992px) {
  section {
    margin-bottom: calc(var(--spacing-block) * var(--spacing-factor-lg) * 2);
  }
}

@media (min-width: 1200px) {
  section {
    margin-bottom: calc(var(--spacing-block) * var(--spacing-factor-xl) * 2);
  }
}

/**
 * Grid
 * Minimal grid system with auto-layout columns
 */
.grid {
  grid-column-gap: var(--spacing-gutter);
  grid-row-gap: var(--spacing-gutter);
  display: grid;
  grid-template-columns: 1fr;
  margin: 0;
}

@media (min-width: 992px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(0%, 1fr));
  }
}

.grid > * {
  min-width: 0;
}

/**
 * Horizontal scroller (<figure>)
 */
figure {
  display: block;
  margin: 0;
  padding: 0;
  overflow-x: auto;
}

figure figcaption {
  padding: calc(var(--spacing-gutter) / 2) 0;
  color: var(--muted-text);
}

/**
 * Typography
 */
b,
strong {
  font-weight: bolder;
}

sub,
sup {
  position: relative;
  font-size: 0.75rem;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25rem;
}

sup {
  top: -0.5rem;
}

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

address,
blockquote,
dl,
figure,
form,
ol,
p,
pre,
table,
ul {
  margin-top: 0;
  margin-bottom: var(--spacing-typography);
  color: var(--text);
  font-size: 1rem;
  font-style: normal;
}

a {
  background-color: transparent;
  color: var(--primary);
  text-decoration: none;
  transition: background-color var(--transition), color var(--transition),
    text-decoration var(--transition), box-shadow var(--transition);
}

a:hover,
a:active,
a:focus {
  color: var(--primary-hover);
  text-decoration: underline;
}

a:focus {
  outline: none;
  background-color: var(--primary-focus);
}

a.secondary {
  color: var(--secondary);
  text-decoration: underline;
}

a.secondary:hover,
a.secondary:active,
a.secondary:focus {
  color: var(--secondary-hover);
}

a.secondary:focus {
  background-color: var(--secondary-focus);
}

a.contrast {
  color: var(--contrast);
  text-decoration: underline;
}

a.contrast:hover,
a.contrast:active,
a.contrast:focus {
  color: var(--contrast-hover);
}

a.contrast:focus {
  background-color: var(--contrast-focus);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: var(--spacing-typography);
  font-family: var(--titles-font);
  font-weight: var(--titles-weight);
}

h1 {
  margin-bottom: calc(var(--spacing-typography) * 2);
  color: var(--h1);
  font-size: var(--h1-size);
}

h2 {
  margin-bottom: calc(var(--spacing-typography) * 1.75);
  color: var(--h2);
  font-size: var(--h2-size);
}

h3 {
  margin-bottom: calc(var(--spacing-typography) * 1.5);
  color: var(--h3);
  font-size: var(--h3-size);
}

h4 {
  margin-bottom: calc(var(--spacing-typography) * 1.25);
  color: var(--h4);
  font-size: var(--h4-size);
}

h5 {
  margin-bottom: calc(var(--spacing-typography) * 1.125);
  color: var(--h5);
  font-size: var(--h5-size);
}

h6 {
  color: var(--h6);
  font-size: var(--h6-size);
}

address ~ h1,
blockquote ~ h1,
dl ~ h1,
figure ~ h1,
form ~ h1,
ol ~ h1,
p ~ h1,
pre ~ h1,
table ~ h1,
ul ~ h1 {
  margin-top: calc(var(--spacing-typography) * 2);
}

address ~ h2,
blockquote ~ h2,
dl ~ h2,
figure ~ h2,
form ~ h2,
ol ~ h2,
p ~ h2,
pre ~ h2,
table ~ h2,
ul ~ h2 {
  margin-top: calc(var(--spacing-typography) * 1.75);
}

address ~ h3,
blockquote ~ h3,
dl ~ h3,
figure ~ h3,
form ~ h3,
ol ~ h3,
p ~ h3,
pre ~ h3,
table ~ h3,
ul ~ h3 {
  margin-top: calc(var(--spacing-typography) * 1.5);
}

address ~ h4,
blockquote ~ h4,
dl ~ h4,
figure ~ h4,
form ~ h4,
ol ~ h4,
p ~ h4,
pre ~ h4,
table ~ h4,
ul ~ h4 {
  margin-top: calc(var(--spacing-typography) * 1.25);
}

address ~ h5,
blockquote ~ h5,
dl ~ h5,
figure ~ h5,
form ~ h5,
ol ~ h5,
p ~ h5,
pre ~ h5,
table ~ h5,
ul ~ h5 {
  margin-top: calc(var(--spacing-typography) * 1.125);
}

address ~ h6,
blockquote ~ h6,
dl ~ h6,
figure ~ h6,
form ~ h6,
ol ~ h6,
p ~ h6,
pre ~ h6,
table ~ h6,
ul ~ h6 {
  margin-top: calc(var(--spacing-typography));
}

hgroup {
  margin-bottom: var(--spacing-typography);
}

hgroup * {
  margin-bottom: 0;
}

hgroup > *:last-child {
  color: var(--muted-text);
  font-family: unset;
  font-size: 1.125rem;
  font-weight: unset;
}

p {
  margin-bottom: var(--spacing-typography);
}

small {
  font-size: 85%;
}

@media (min-width: 576px) {
  small {
    font-size: 83%;
  }
}

@media (min-width: 768px) {
  small {
    font-size: 81%;
  }
}

@media (min-width: 992px) {
  small {
    font-size: 79%;
  }
}

@media (min-width: 1200px) {
  small {
    font-size: 77%;
  }
}

ul,
ol {
  padding-left: var(--spacing-typography);
}

ul li,
ol li {
  margin-bottom: calc(var(--spacing-typography) / 4);
}

ul li {
  list-style: square;
}

mark {
  padding: 0.125rem 0.25rem;
  background: var(--mark);
  color: var(--mark-text);
  vertical-align: middle;
}

blockquote {
  display: block;
  margin: var(--spacing-typography) 0;
  padding: var(--spacing-gutter);
  border-left: 0.25rem solid var(--muted-border);
}

blockquote footer {
  margin-top: calc(var(--spacing-typography) / 2);
  color: var(--muted-text);
}

abbr[title] {
  border-bottom: 1px dotted;
  text-decoration: none;
  cursor: help;
}

ins {
  color: var(--valid);
  text-decoration: none;
}

del {
  color: var(--invalid);
}

::selection {
  background-color: var(--primary-focus);
}

/**
 * Embedded content
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

iframe {
  border-style: none;
}

img {
  max-width: 100%;
  height: auto;
  border-style: none;
}

svg:not([fill]) {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

/**
 * Button
 */
button {
  margin: 0;
  overflow: visible;
  font-family: inherit;
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

button {
  display: block;
  width: 100%;
  margin-bottom: var(--spacing-typography);
}

a[role="button"] {
  display: inline-block;
  text-decoration: none;
}

button,
input[type="submit"],
input[type="button"],
input[type="reset"],
[type="file"]::-webkit-file-upload-button,
a[role="button"] {
  padding: var(--button-spacing-vertical) var(--button-spacing-horizontal);
  border: var(--button-border-width) solid var(--primary-border);
  border-radius: var(--button-round);
  outline: none;
  background-color: var(--primary);
  box-shadow: var(--button-shadow);
  color: var(--primary-inverse);
  font-size: 1rem;
  font-weight: var(--buttons-weight);
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  transition: background-color var(--transition), border-color var(--transition),
    color var(--transition), box-shadow var(--transition);
}

button:hover,
button:active,
button:focus,
input[type="submit"]:hover,
input[type="submit"]:active,
input[type="submit"]:focus,
input[type="button"]:hover,
input[type="button"]:active,
input[type="button"]:focus,
input[type="reset"]:hover,
input[type="reset"]:active,
input[type="reset"]:focus,
[type="file"]::-webkit-file-upload-button:hover,
[type="file"]::-webkit-file-upload-button:active,
[type="file"]::-webkit-file-upload-button:focus,
a[role="button"]:hover,
a[role="button"]:active,
a[role="button"]:focus {
  border-color: var(--primary-hover-border);
  background-color: var(--primary-hover);
  box-shadow: var(--button-hover-shadow);
}

button:focus,
input[type="submit"]:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
[type="file"]::-webkit-file-upload-button:focus,
a[role="button"]:focus {
  box-shadow: var(--button-hover-shadow),
    0 0 0 var(--button-outline-width) var(--primary-focus);
}

input[type="reset"] {
  cursor: pointer;
}

button[disabled],
input[type="submit"][disabled],
input[type="button"][disabled],
input[type="reset"][disabled],
a[role="button"][disabled] {
  opacity: 0.5;
  pointer-events: none;
}

/**
 * Button Styles
 */
button.secondary,
input[type="submit"].secondary,
input[type="reset"],
a[role="button"].secondary {
  border: var(--button-border-width) solid var(--secondary-border);
  background-color: var(--secondary);
  color: var(--secondary-inverse);
}

button.secondary:hover,
button.secondary:active,
button.secondary:focus,
input[type="submit"].secondary:hover,
input[type="submit"].secondary:active,
input[type="submit"].secondary:focus,
input[type="reset"]:hover,
input[type="reset"]:active,
input[type="reset"]:focus,
a[role="button"].secondary:hover,
a[role="button"].secondary:active,
a[role="button"].secondary:focus {
  border-color: var(--secondary-hover-border);
  background-color: var(--secondary-hover);
}

button.secondary:focus,
input[type="submit"].secondary:focus,
input[type="reset"]:focus,
a[role="button"].secondary:focus {
  box-shadow: var(--button-hover-shadow),
    0 0 0 var(--button-outline-width) var(--secondary-focus) !important;
}

button.contrast,
input[type="submit"].contrast,
input[type="reset"].contrast,
a[role="button"].contrast {
  border: var(--button-border-width) solid var(--contrast-border);
  background-color: var(--contrast);
  color: var(--contrast-inverse);
}

button.contrast:hover,
button.contrast:active,
button.contrast:focus,
input[type="submit"].contrast:hover,
input[type="submit"].contrast:active,
input[type="submit"].contrast:focus,
input[type="reset"].contrast:hover,
input[type="reset"].contrast:active,
input[type="reset"].contrast:focus,
a[role="button"].contrast:hover,
a[role="button"].contrast:active,
a[role="button"].contrast:focus {
  border-color: var(--contrast-hover-border);
  background-color: var(--contrast-hover);
}

button.contrast:focus,
input[type="submit"].contrast:focus,
input[type="reset"].contrast:focus,
a[role="button"].contrast:focus {
  box-shadow: var(--button-hover-shadow),
    0 0 0 var(--button-outline-width) var(--contrast-focus);
}

button.outline,
input[type="submit"].outline,
a[role="button"].outline {
  border: var(--button-border-width) solid var(--primary-border);
  background-color: transparent;
  color: var(--primary);
}

button.outline:hover,
button.outline:active,
button.outline:focus,
input[type="submit"].outline:hover,
input[type="submit"].outline:active,
input[type="submit"].outline:focus,
a[role="button"].outline:hover,
a[role="button"].outline:active,
a[role="button"].outline:focus {
  border: var(--button-border-width) solid var(--primary-hover-border);
  color: var(--primary-hover);
}

button.outline.secondary,
input[type="submit"].outline.secondary,
input[type="reset"].outline,
a[role="button"].outline.secondary {
  border: var(--button-border-width) solid var(--secondary-border);
  background-color: transparent;
  color: var(--secondary);
}

button.outline.secondary:hover,
button.outline.secondary:active,
button.outline.secondary:focus,
input[type="submit"].outline.secondary:hover,
input[type="submit"].outline.secondary:active,
input[type="submit"].outline.secondary:focus,
input[type="reset"].outline:hover,
input[type="reset"].outline:active,
input[type="reset"].outline:focus,
a[role="button"].outline.secondary:hover,
a[role="button"].outline.secondary:active,
a[role="button"].outline.secondary:focus {
  border: var(--button-border-width) solid var(--secondary-hover-border);
  color: var(--secondary-hover);
}

button.outline.contrast,
input[type="submit"].outline.contrast,
input[type="reset"].outline.contrast,
a[role="button"].outline.contrast {
  border: var(--button-border-width) solid var(--contrast-border);
  background-color: transparent;
  color: var(--contrast);
}

button.outline.contrast:hover,
button.outline.contrast:active,
button.outline.contrast:focus,
input[type="submit"].outline.contrast:hover,
input[type="submit"].outline.contrast:active,
input[type="submit"].outline.contrast:focus,
input[type="reset"].outline.contrast:hover,
input[type="reset"].outline.contrast:active,
input[type="reset"].outline.contrast:focus,
a[role="button"].outline.contrast:hover,
a[role="button"].outline.contrast:active,
a[role="button"].outline.contrast:focus {
  border: var(--button-border-width) solid var(--contrast-hover-border);
  color: var(--contrast-hover);
}

/**
 * Form elements
 */
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 1rem;
  letter-spacing: inherit;
  line-height: var(--line-height);
}

input {
  overflow: visible;
}

select {
  text-transform: none;
}

legend {
  max-width: 100%;
  padding: 0;
  color: inherit;
  white-space: normal;
}

textarea {
  margin: 0;
  overflow: auto;
  resize: vertical;
  resize: block;
}

[type="checkbox"],
[type="radio"] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-ms-expand {
  display: none;
}

[type="file"],
[type="range"] {
  padding: 0;
  border-width: 0;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="range"]) {
  height: calc(
    (1rem * var(--line-height)) + (var(--form-element-spacing-vertical) * 2) +
      (var(--form-element-border-width) * 2)
  );
}

fieldset {
  margin: 0;
  margin-bottom: var(--spacing-typography);
  padding: 0;
  border: 0;
}

label,
fieldset legend {
  display: block;
  margin-bottom: var(--spacing-form-element);
  vertical-align: middle;
}

input:not([type="checkbox"]):not([type="radio"]),
select,
textarea,
form small {
  display: block;
  width: 100%;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="range"]):not([type="file"]),
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: var(--form-element-spacing-vertical)
    var(--form-element-spacing-horizontal);
  vertical-align: middle;
}

input,
select,
textarea {
  border: var(--form-element-border-width) solid var(--input-border);
  border-radius: var(--block-round);
  outline: none;
  background-color: var(--input-background);
  color: var(--text);
  font-weight: var(--form-element-weight);
  transition: background-color var(--transition), border-color var(--transition),
    color var(--transition), box-shadow var(--transition);
}

input::placeholder,
input::-webkit-input-placeholder,
select::placeholder,
select::-webkit-input-placeholder,
textarea::placeholder,
textarea::-webkit-input-placeholder {
  color: var(--muted-text);
  opacity: 1;
}

input:active,
input:focus,
select:active,
select:focus,
textarea:active,
textarea:focus {
  border-color: var(--input-hover-border);
  background-color: var(--input-hover-background);
}

input[readonly],
input[disabled],
select[readonly],
select[disabled],
textarea[readonly],
textarea[disabled] {
  border-color: var(--muted-border);
  box-shadow: none;
}

input[readonly] ~ label,
input[disabled] ~ label,
select[readonly] ~ label,
select[disabled] ~ label,
textarea[readonly] ~ label,
textarea[disabled] ~ label {
  color: var(--muted-text);
}

input[readonly]:active,
input[readonly]:focus,
input[disabled]:active,
input[disabled]:focus,
select[readonly]:active,
select[readonly]:focus,
select[disabled]:active,
select[disabled]:focus,
textarea[readonly]:active,
textarea[readonly]:focus,
textarea[disabled]:active,
textarea[disabled]:focus {
  box-shadow: none !important;
}

input[disabled]:not([type="reset"]):not([type="submit"]):not([type="button"]),
select[disabled]:not([type="reset"]):not([type="submit"]):not([type="button"]),
textarea[disabled]:not([type="reset"]):not([type="submit"]):not([type="button"]) {
  background-color: var(--muted-background);
}

input[disabled],
select[disabled],
textarea[disabled] {
  opacity: 0.66;
}

input .valid,
input .invalid,
input[aria-invalid],
select .valid,
select .invalid,
select[aria-invalid],
textarea .valid,
textarea .invalid,
textarea[aria-invalid] {
  padding-right: 2rem;
  background-position: center right 0.75rem;
  background-repeat: no-repeat;
  background-size: 1rem auto;
}

input .valid,
input[aria-invalid="false"],
select .valid,
select[aria-invalid="false"],
textarea .valid,
textarea[aria-invalid="false"] {
  background-image: var(--icon-valid);
}

input .invalid,
input[aria-invalid="true"],
select .invalid,
select[aria-invalid="true"],
textarea .invalid,
textarea[aria-invalid="true"] {
  background-image: var(--icon-invalid);
}

input:not([type="checkbox"]):not([type="radio"]),
select,
textarea {
  margin-bottom: var(--spacing-typography);
}

input:not([type="range"]):not([type="file"]):focus,
select:focus,
textarea:focus {
  box-shadow: 0 0 0 var(--form-element-outline-width) var(--input-focus);
}

select::-ms-expand {
  border: 0;
  background-color: transparent;
}

select:not([multiple]):not([size]) {
  padding-right: calc(var(--form-element-spacing-horizontal) + 1.5rem);
  background-image: var(--icon-chevron);
  background-position: center right 0.75rem;
  background-repeat: no-repeat;
  background-size: 1rem auto;
}

form small {
  color: var(--muted-text);
}

input + small,
select + small,
textarea + small {
  margin-top: calc(var(--spacing-typography) * -0.75);
  margin-bottom: var(--spacing-typography);
}

label > input,
label > select,
label > textarea {
  margin-top: var(--spacing-form-element);
}

/**
 * Form elements
 * Checkboxes & Radios
 */
[type="checkbox"],
[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.125rem;
  margin-right: 0.375rem;
  border-width: var(--checkbox-radio-border-width);
  vertical-align: middle;
  cursor: pointer;
}

[type="checkbox"]::-ms-check,
[type="radio"]::-ms-check {
  display: none;
}

[type="checkbox"]:checked,
[type="checkbox"]:indeterminate,
[type="radio"]:checked,
[type="radio"]:indeterminate {
  border-color: var(--input-hover-border);
  background-color: var(--input-hover-border);
  background-image: var(--icon-checkbox);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 0.75rem auto;
}

[type="checkbox"]:indeterminate,
[type="radio"]:indeterminate {
  background-image: var(--icon-minus);
}

[type="checkbox"] ~ label,
[type="radio"] ~ label {
  display: inline-block;
  margin-right: 0.375rem;
  margin-bottom: 0;
  cursor: pointer;
}

[type="radio"] {
  border-radius: 50%;
}

[type="radio"]:checked {
  border-width: 0.33rem;
  border-color: var(--input-hover-border);
  background-color: var(--input-inverse);
  background-image: none;
}

[type="checkbox"][role="switch"] {
  width: 2.25rem;
  height: 1.25rem;
  border: var(--switch-border-width) solid var(--input-border);
  border-radius: 1.25rem;
  background-color: var(--input-border);
  line-height: 1.25rem;
}

[type="checkbox"][role="switch"]:before {
  display: block;
  width: calc(1.25rem - (var(--switch-border-width) * 2));
  height: 100%;
  border-radius: 50%;
  background-color: var(--input-inverse);
  content: "";
  transition: margin 0.1s ease-in-out;
}

[type="checkbox"][role="switch"]:checked {
  border-color: var(--input-hover-border);
  background-color: var(--input-hover-border);
  background-image: none;
}

[type="checkbox"][role="switch"]:checked::before {
  margin-right: 0;
  margin-left: calc(1.125rem - var(--switch-border-width));
}

/**
 * Form elements
 * Alternatives input types (Not Checkboxes & Radios)
 */
[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

[type="color"]::-moz-focus-inner {
  padding: 0;
}

[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: calc(var(--block-round) / 2);
}

[type="color"]::-moz-color-swatch {
  border: none;
  border-radius: calc(var(--block-round) / 2);
}

[type="date"],
[type="datetime-local"],
[type="month"],
[type="time"],
[type="week"] {
  background-image: var(--icon-date);
  background-position: center right 0.75rem;
  background-repeat: no-repeat;
  background-size: 1rem auto;
}

[type="date"]::-webkit-calendar-picker-indicator,
[type="datetime-local"]::-webkit-calendar-picker-indicator,
[type="month"]::-webkit-calendar-picker-indicator,
[type="time"]::-webkit-calendar-picker-indicator,
[type="week"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

[type="time"] {
  background-image: var(--icon-time);
}

[type="file"] {
  padding: calc(var(--form-element-spacing-vertical) / 2) 0;
  border: none;
  border-radius: 0;
  background: none;
}

[type="file"]::-webkit-file-upload-button {
  padding: calc(var(--form-element-spacing-vertical) / 2)
    calc(var(--form-element-spacing-horizontal) / 2);
  border: var(--button-border-width) solid var(--secondary-border);
  background-color: var(--secondary);
  box-shadow: var(--button-shadow);
  color: var(--secondary-inverse);
  transition: background-color var(--transition), border-color var(--transition),
    color var(--transition), box-shadow var(--transition);
}

[type="file"]:hover,
[type="file"]:active,
[type="file"]:focus {
  border: none;
  background: none;
}

[type="file"]:hover::-webkit-file-upload-button,
[type="file"]:active::-webkit-file-upload-button,
[type="file"]:focus::-webkit-file-upload-button {
  border-color: var(--secondary-hover-border);
  background-color: var(--secondary-hover);
}

[type="file"]:focus::-webkit-file-upload-button {
  box-shadow: none;
}

[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 1.25rem;
  background: transparent;
}

[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.25rem;
  border-radius: var(--block-round);
  background-color: var(--input-border);
  transition: background-color var(--transition), box-shadow var(--transition);
}

[type="range"]::-moz-range-track {
  width: 100%;
  height: 0.25rem;
  border-radius: var(--block-round);
  background-color: var(--input-border);
  transition: background-color var(--transition), box-shadow var(--transition);
}

[type="range"]::-ms-track {
  width: 100%;
  height: 0.25rem;
  border-radius: var(--block-round);
  background-color: var(--input-border);
  transition: background-color var(--transition), box-shadow var(--transition);
}

[type="range"]::-ms-fill-lower {
  background-color: var(--input-border);
}

[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.5rem;
  border: 2px solid var(--input-background);
  border-radius: 50%;
  background-color: var(--text);
  cursor: pointer;
  transition: background-color var(--transition), transform var(--transition);
}

[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.5rem;
  border: 2px solid var(--input-background);
  border-radius: 50%;
  background-color: var(--text);
  cursor: pointer;
  transition: background-color var(--transition), transform var(--transition);
}

[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.5rem;
  border: 2px solid var(--input-background);
  border-radius: 50%;
  background-color: var(--text);
  cursor: pointer;
  transition: background-color var(--transition), transform var(--transition);
}

[type="range"]:focus {
  background: transparent !important;
}

[type="range"]:focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 0.1rem var(--input-focus);
}

[type="range"]:focus::-moz-range-track {
  box-shadow: 0 0 0 0.1rem var(--input-focus);
}

[type="range"]:focus::-ms-track {
  box-shadow: 0 0 0 0.1rem var(--input-focus);
}

[type="range"]:focus::-ms-fill-lower {
  box-shadow: 0 0 0 0.1rem var(--input-focus);
}

[type="range"]:active {
  background: transparent !important;
}

[type="range"]:active::-webkit-slider-thumb {
  transform: scale(1.25);
  background-color: var(--primary);
}

[type="range"]:active::-moz-range-thumb {
  transform: scale(1.25);
  background-color: var(--primary);
}

[type="range"]:active::-ms-thumb {
  transform: scale(1.25);
  background-color: var(--primary);
}

[type="range"]:focus {
  box-shadow: none;
}

[type="search"] {
  border-radius: 5rem;
  padding-left: calc(
    var(--form-element-spacing-horizontal) + 1.5rem
  ) !important;
  background-image: var(--icon-search);
  background-position: center left 0.75rem;
  background-repeat: no-repeat;
  background-size: 1rem auto;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

/**
 * Table
 */
table {
  width: 100%;
  border-color: inherit;
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: 0;
}

th,
td {
  padding: calc(var(--spacing-gutter) / 2) var(--spacing-gutter);
  border-bottom: 1px solid var(--table-border);
  color: var(--muted-text);
  font-size: 0.875rem;
  font-weight: var(--text-weight);
  text-align: left;
}

th,
thead td {
  color: var(--text);
  font-size: 1rem;
}

thead th,
thead td {
  border-bottom: 3px solid var(--table-border);
}

table[role="grid"] tbody tr:nth-child(odd) {
  background-color: var(--table-stripping);
}

/**
 * Code
 */
pre,
code,
kbd,
samp {
  font-family: var(--code-font);
  font-size: 1rem;
}

pre {
  -ms-overflow-style: scrollbar;
  overflow: auto;
}

pre,
code,
kbd {
  background: var(--code-inlined);
  color: var(--code-color-1);
  font-size: 85%;
  font-weight: var(--code-weight);
  line-height: initial;
}

@media (min-width: 576px) {
  pre,
  code,
  kbd {
    font-size: 83%;
  }
}

@media (min-width: 768px) {
  pre,
  code,
  kbd {
    font-size: 81%;
  }
}

@media (min-width: 992px) {
  pre,
  code,
  kbd {
    font-size: 79%;
  }
}

@media (min-width: 1200px) {
  pre,
  code,
  kbd {
    font-size: 77%;
  }
}

code,
kbd {
  display: inline-block;
  padding: 0.375rem 0.5rem;
  border-radius: var(--block-round);
}

pre {
  display: block;
  margin-bottom: var(--spacing-block);
  padding: var(--spacing-block) var(--spacing-gutter);
  overflow-x: auto;
  background: var(--code-background);
}

pre > code {
  display: block;
  padding: 0;
  background: transparent;
  font-size: 14px;
  line-height: var(--line-height);
}

code b {
  color: var(--code-color-2);
  font-weight: var(--code-weight);
}

code i {
  color: var(--code-color-3);
  font-style: normal;
}

code u {
  color: var(--code-color-4);
  text-decoration: none;
}

code em {
  color: var(--code-color-5);
  font-style: normal;
}

kbd {
  background-color: var(--secondary);
  color: var(--secondary-inverse);
  font-weight: bolder;
}

/**
 * Accessibility & User interaction
 */
[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"],
[disabled] {
  cursor: not-allowed;
}

[aria-hidden="false"][hidden] {
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
}

/**
 * Miscs
 */
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: none;
  border-top: 1px solid var(--muted-border);
}

[hidden],
template {
  display: none;
}

dialog {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  padding: 1em;
  border: solid;
  background-color: white;
  color: black;
}

dialog:not([open]) {
  display: none;
}

canvas {
  display: inline-block;
}

/**
 * Accordion (<details>)
 * Inspiration: https://codepen.io/koca/pen/RyeLLV
 */
details {
  display: block;
  margin-bottom: var(--spacing-typography);
  padding-bottom: calc(var(--spacing-typography) / 2);
  border-bottom: 1px solid var(--muted-border);
}

details summary {
  line-height: 1rem;
  list-style-type: none;
  cursor: pointer;
}

details summary::-webkit-details-marker {
  display: none;
}

details summary::marker {
  display: none;
}

details summary::-moz-list-bullet {
  list-style-type: none;
}

details summary::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  float: right;
  transform: rotate(-90deg);
  background-image: var(--icon-chevron);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem auto;
  content: "";
  transition: transform var(--transition);
}

details summary:focus {
  outline: none;
}

details summary ~ * {
  margin-top: calc(var(--spacing-typography) / 2);
}

details summary ~ * ~ * {
  margin-top: 0;
}

details[open] summary {
  margin-bottom: calc(var(--spacing-typography) / 4);
  color: var(--muted-text);
}

details[open] summary::after {
  transform: rotate(0);
}

/**
 * Card (<article>)
 */
article {
  margin: var(--spacing-block) 0;
  padding: var(--spacing-block) var(--spacing-gutter);
  overflow: hidden;
  border-radius: var(--block-round);
  background: var(--card-background);
  box-shadow: var(--card-shadow);
}

@media (min-width: 576px) {
  article {
    margin: calc(var(--spacing-block) * var(--spacing-factor-sm)) 0;
    padding: calc(var(--spacing-block) * var(--spacing-factor-sm));
  }
}

@media (min-width: 768px) {
  article {
    margin: calc(var(--spacing-block) * var(--spacing-factor-md)) 0;
    padding: calc(var(--spacing-block) * var(--spacing-factor-md));
  }
}

@media (min-width: 992px) {
  article {
    margin: calc(var(--spacing-block) * var(--spacing-factor-lg)) 0;
    padding: calc(var(--spacing-block) * var(--spacing-factor-lg));
  }
}

@media (min-width: 1200px) {
  article {
    margin: calc(var(--spacing-block) * var(--spacing-factor-xl)) 0;
    padding: calc(var(--spacing-block) * var(--spacing-factor-xl));
  }
}

article > *:not(header):not(footer):not(pre):last-child {
  margin-bottom: 0 !important;
}

/**
 * Card sectionning (<article> > header, footer, pre)
 */
article > header,
article > footer {
  background-color: var(--card-sections);
}

article > header,
article > footer,
article > pre {
  margin: calc(var(--spacing-gutter) * -1);
  padding: var(--spacing-block) var(--spacing-gutter);
}

@media (min-width: 576px) {
  article > header,
  article > footer,
  article > pre {
    margin: calc(var(--spacing-block) * var(--spacing-factor-sm) * -1);
    padding: var(--spacing-block)
      calc(var(--spacing-block) * var(--spacing-factor-sm));
  }
}

@media (min-width: 768px) {
  article > header,
  article > footer,
  article > pre {
    margin: calc(var(--spacing-block) * var(--spacing-factor-md) * -1);
    padding: var(--spacing-block)
      calc(var(--spacing-block) * var(--spacing-factor-md));
  }
}

@media (min-width: 992px) {
  article > header,
  article > footer,
  article > pre {
    margin: calc(var(--spacing-block) * var(--spacing-factor-lg) * -1);
    padding: var(--spacing-block)
      calc(var(--spacing-block) * var(--spacing-factor-lg));
  }
}

@media (min-width: 1200px) {
  article > header,
  article > footer,
  article > pre {
    margin: calc(var(--spacing-block) * var(--spacing-factor-xl) * -1);
    padding: var(--spacing-block)
      calc(var(--spacing-block) * var(--spacing-factor-xl));
  }
}

article > header {
  margin-top: calc(var(--spacing-gutter) * -2);
  margin-bottom: var(--spacing-block);
}

@media (min-width: 576px) {
  article > header {
    margin-top: calc(var(--spacing-block) * var(--spacing-factor-sm) * -1);
    margin-bottom: calc(var(--spacing-block) * var(--spacing-factor-sm));
  }
}

@media (min-width: 768px) {
  article > header {
    margin-top: calc(var(--spacing-block) * var(--spacing-factor-md) * -1);
    margin-bottom: calc(var(--spacing-block) * var(--spacing-factor-md));
  }
}

@media (min-width: 992px) {
  article > header {
    margin-top: calc(var(--spacing-block) * var(--spacing-factor-lg) * -1);
    margin-bottom: calc(var(--spacing-block) * var(--spacing-factor-lg));
  }
}

@media (min-width: 1200px) {
  article > header {
    margin-top: calc(var(--spacing-block) * var(--spacing-factor-xl) * -1);
    margin-bottom: calc(var(--spacing-block) * var(--spacing-factor-xl));
  }
}

article > footer,
article > pre {
  margin-top: var(--spacing-block);
  margin-bottom: calc(var(--spacing-gutter) * -2);
}

@media (min-width: 576px) {
  article > footer,
  article > pre {
    margin-top: calc(var(--spacing-block) * var(--spacing-factor-sm));
    margin-bottom: calc(var(--spacing-block) * var(--spacing-factor-sm) * -1);
  }
}

@media (min-width: 768px) {
  article > footer,
  article > pre {
    margin-top: calc(var(--spacing-block) * var(--spacing-factor-md));
    margin-bottom: calc(var(--spacing-block) * var(--spacing-factor-md) * -1);
  }
}

@media (min-width: 992px) {
  article > footer,
  article > pre {
    margin-top: calc(var(--spacing-block) * var(--spacing-factor-lg));
    margin-bottom: calc(var(--spacing-block) * var(--spacing-factor-lg) * -1);
  }
}

@media (min-width: 1200px) {
  article > footer,
  article > pre {
    margin-top: calc(var(--spacing-block) * var(--spacing-factor-xl));
    margin-bottom: calc(var(--spacing-block) * var(--spacing-factor-xl) * -1);
  }
}

/**
 * Nav
 */
nav,
nav ul {
  display: flex;
}

nav {
  justify-content: space-between;
}

nav ol,
nav ul {
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

nav ol:first-of-type,
nav ul:first-of-type {
  margin-left: calc(var(--spacing-gutter) * -0.5);
}

nav ol:last-of-type,
nav ul:last-of-type {
  margin-right: calc(var(--spacing-gutter) * -0.5);
}

nav li {
  display: inline-block;
  margin: 0;
  padding: var(--spacing-gutter) calc(var(--spacing-gutter) / 2);
}

nav li > *,
nav li > input:not([type="checkbox"]):not([type="radio"]) {
  margin-bottom: 0;
}

nav a {
  display: block;
  margin: calc(var(--spacing-gutter) * -1) calc(var(--spacing-gutter) * -0.5);
  padding: var(--spacing-gutter) calc(var(--spacing-gutter) / 2);
  border-radius: var(--block-round);
  text-decoration: none !important;
}

nav a:hover,
nav a:active,
nav a:focus {
  text-decoration: none !important;
}

aside nav,
aside ol,
aside ul,
aside li {
  display: block;
}

aside li {
  padding: calc(var(--spacing-gutter) / 2);
}

aside li a {
  margin: calc(var(--spacing-gutter) * -0.5);
  padding: calc(var(--spacing-gutter) / 2);
}

/**
 * Progress
 */
progress {
  display: inline-block;
  vertical-align: baseline;
}

progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 100%;
  height: 0.5rem;
  margin-bottom: calc(var(--spacing-typography) / 2);
  overflow: hidden;
  border: 0;
  border-radius: var(--block-round);
  background-color: var(--muted-background);
  color: var(--primary);
}

progress::-webkit-progress-bar {
  border-radius: var(--block-round);
  background: transparent;
}

progress[value]::-webkit-progress-value {
  background-color: var(--primary);
}

progress::-moz-progress-bar {
  background-color: var(--primary);
}

progress:indeterminate {
  background: var(--muted-background)
    linear-gradient(to right, var(--primary) 30%, var(--muted-background) 30%)
    top left/150% 150% no-repeat;
  animation: progress-indeterminate 1s linear infinite;
}

progress:indeterminate[value]::-webkit-progress-value {
  background-color: transparent;
}

progress:indeterminate::-moz-progress-bar {
  background-color: transparent;
}

@keyframes progress-indeterminate {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/**
 * Tooltip ([data-tooltip])
 */
[data-tooltip] {
  position: relative;
}

[data-tooltip]:not(a):not(button):not(input) {
  border-bottom: 1px dotted;
  text-decoration: none;
  cursor: help;
}

[data-tooltip]::before,
[data-tooltip]::after {
  display: block;
  z-index: 99;
  position: absolute;
  bottom: 100%;
  left: 50%;
  padding: 0.25rem 0.5rem;
  overflow: hidden;
  transform: translate(-50%, -0.25rem);
  border-radius: var(--block-round);
  background: var(--contrast);
  color: var(--contrast-inverse);
  font-size: 0.85rem;
  font-style: normal;
  font-weight: var(--text-weight);
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  content: attr(data-tooltip);
  opacity: 0;
  pointer-events: none;
}

[data-tooltip]::after {
  padding: 0;
  transform: translate(-50%, 0rem);
  border-top: 0.3rem solid;
  border-right: 0.3rem solid transparent;
  border-left: 0.3rem solid transparent;
  border-radius: 0;
  background-color: transparent;
  color: var(--contrast);
  content: "";
}

[data-tooltip]:focus::before,
[data-tooltip]:focus::after,
[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
  opacity: 1;
  animation-duration: 0.2s;
  animation-name: slide;
}

[data-tooltip]:focus::after,
[data-tooltip]:hover::after {
  animation-name: slideCaret;
}

@keyframes slide {
  from {
    opacity: 0;
    transform: translate(-50%, 0.75rem);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -0.25rem);
  }
}

@keyframes slideCaret {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: translate(-50%, -0.25rem);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0rem);
  }
}

/**
 * Reduce Motion Features
 */
@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    background-attachment: initial !important;
    animation-duration: 1ms !important;
    animation-delay: -1ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-delay: 0s !important;
    transition-duration: 0s !important;
  }
}
